import {CountryRepository} from 'IndexedDB';
import Axios from './Axios';

const countryRepository = new CountryRepository();

export const getCountries = async () => {
    let countries = await countryRepository.getCountries();

    if (countries.length == 0) {
        const response = await Axios.instance().get(`countries`);

        if (response.data.success) {
            countries = response.data.Countries;
            await countryRepository.addCountries(response.data.Countries);
        } else throw response.data.errors;
    }

    return countries;
};
