// @flow
import {HandlerBuilder} from 'shared';
import {HingeStyleCabinet, HingeStyleRoom} from 'IndexedDB';

export const getHingeStylesForRoom = async (): Promise<HingeStyleRoom[]> => {
    return HandlerBuilder.createInstance('/hingeStyle/room')
        .setStoreName('hinge_styles_room')
        .setResultName('hingeStyles')
        .setObjectProcessor((object) => {
            return Object.assign(new HingeStyleRoom(), object);
        })
        .build()
        .getData();
};

export const getHingeStylesForCabinet = async (
    cabinetId: number
): Promise<HingeStyleCabinet[]> => {
    if (typeof cabinetId === 'undefined') {
        return [];
    }

    return HandlerBuilder.createInstance(`/hingeStyle/cabinet/${cabinetId}`)
        .setStoreName('hinge_styles_cabinet')
        .setIndex(cabinetId, 'cabinetId')
        .setResultName('hingeStyles')
        .setObjectProcessor((object) => {
            object.cabinetId = cabinetId;
            return Object.assign(new HingeStyleCabinet(), object);
        })
        .build()
        .getData();
};
