import {
    ProductRepository,
    Product,
    ProductSubStyle,
    ProductStyle,
} from 'IndexedDB';
import {getApplianceType, HandlerBuilder} from 'shared';
import Axios from './Axios';
import {store} from 'store/customer';
import {ProductApi} from 'components/customer/Product/store/productApi';
import {cloneDeep} from 'lodash';
import {ProductForm} from 'components/customer/Product/entity/ProductForm';

const productRepository = new ProductRepository();

export const getQuickProducts = async () => {
    return HandlerBuilder.createInstance('cabinets/quickproducts')
        .setStoreName('products')
        .setResultName('products')
        .setObjectProcessor((object: Object): Product => {
            object.quickProducts = 1;
            return Object.assign(new Product(), object);
        })
        .build()
        .getData();
};

export const getProductStyles = async () => {
    return HandlerBuilder.createInstance('cabinets/productStyles')
        .setStoreName('product_styles')
        .setResultName('product_styles')
        .setObjectProcessor((object: Object): ProductStyle => {
            if (object.hasOwnProperty('subStyles')) {
                object.subStyles = object.subStyles.map((substyle) => {
                    return Object.assign(new ProductSubStyle(), substyle);
                });
            }

            return Object.assign(new ProductStyle(), object);
        })
        .build()
        .getData();
};

export const getFavouriteProducts = async () => {
    const response = await Axios.instance().get(`cabinets/favouritelist`);

    if (response.data.success) {
        return response.data.favouritesProducts;
    }
};

export const deleteProduct = async (cabinetId, jobId, roomId) => {
    const response = await Axios.instance().delete(
        `cabinets/jobcabinet/${cabinetId}`
    );

    return response;
};

export const getProductBreadcrumb = async (
    category,
    subCategory,
    productStyles = null
) => {
    let categoryData;
    if (productStyles == null) {
        categoryData = await productRepository.getProductStyle(
            parseInt(category)
        );
    } else {
        categoryData = productStyles.filter((style) => style.id == category);
    }

    const breadcrumbs = [];

    if (categoryData.length) {
        breadcrumbs.push({
            id: categoryData[0].id,
            name: categoryData[0].styleName,
        });

        if (subCategory) {
            const subStyle = categoryData[0].subStyles.filter(
                (style) => style.subStyleId == subCategory
            );

            if (subStyle.length) {
                breadcrumbs.push({
                    id: subStyle[0].subStyleId,
                    name: subStyle[0].subStyleName,
                    category,
                });
            }
        }
    }

    return breadcrumbs;
};

export const getProductDetails = async (id) => {
    if (!id) return {};
    const productDetails = await productRepository.getProduct(parseInt(id));

    if (productDetails.length && productDetails[0].hasOwnProperty('name')) {
        return productDetails[0];
    }

    const response = await Axios.instance().get(`cabinets/cabinet/${id}`);

    if (response.data.success) {
        const product = response.data.cabinet;
        product.id = product.type;

        if (
            productDetails.length &&
            !productDetails[0].hasOwnProperty('name') &&
            productDetails[0].id == product.id
        ) {
            await productRepository.addProducts(product);
        }

        return product;
    }
};

export const getAvailableDoors = async (id) => {
    if (!id) return [];

    const productDetails = await productRepository.getProduct(parseInt(id));

    if (
        productDetails.length &&
        productDetails[0].hasOwnProperty('available_doors')
    ) {
        return productDetails[0].available_doors.split(',').map(Number);
    }

    return [];
};

export const getProductData = async (
    id = false,
    productId,
    roomId,
    userProfile
) => {
    let response;

    if (id)
        response = await Axios.instance().get(`cabinets/jobcabinet/id/${id}`);
    else {
        response = await Axios.instance().get(
            `cabinets/jobcabinet/cabinetType/${productId}/roomId/${roomId}`
        );
    }

    if (response?.data?.job_cabinet?.ext_edge == null) {
        response.data.job_cabinet.ext_edge = {
            id: userProfile.defaultExtEdge,
            brand: userProfile.defaultExtEdgeBrand,
            finish: userProfile.defaultExtEdgeFinish,
        };
    }

    if (response?.data?.job_cabinet?.carc_edge == null) {
        response.data.job_cabinet.carc_edge = {
            id: userProfile.defaultCarcEdge,
            brand: userProfile.defaultCarcEdgeBrand,
            finish: userProfile.defaultCarcEdgeFinish,
        };
    }

    if (response.data.success) {
        return response.data.job_cabinet;
    }
};

export const getFormFieldDefaults = async (productId) => {
    // NOTE: manually calling the endpoint here,
    // this service will basically go away later if/when
    // we get rid of the old quick product stuffs
    const {data, isSuccess} = await store.dispatch(
        ProductApi.endpoints.getProductDefault.initiate({
            cabinetType: Number(productId),
        })
    );

    if (isSuccess) {
        return data;
    }
};

/**
 *
 * @param {number} id
 *
 * @return {Promise<ProductForm>}
 */
export const getProductConfig = async (id) => {
    // NOTE: manually calling the endpoint here,
    // this service will basically go away later if/when
    // we get rid of the old quick product stuffs
    const {data, isSuccess} = await store.dispatch(
        ProductApi.endpoints.getQFPProductStructure.initiate({
            cabinetType: Number(id),
        })
    );

    if (isSuccess) {
        return {
            structure: cloneDeep(data.originalStructure),
            validation: cloneDeep(data.validation),
            dimension: cloneDeep(data.majorDimensions),
        };
    }
};

export const saveProduct = async (product) => {
    const response = await Axios.instance().post(
        'cabinets/jobcabinet/save',
        JSON.stringify(product),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        }
    );

    return response;
};

export const getAppliances = async (type) => {
    let appliances = await productRepository.getAppliances(type);

    if (appliances.length === 0) {
        const response = await Axios.instance().get(
            `customer/kitchen-appliances`
        );

        if (response.data.success) {
            const appliancesList = response.data.data.map((appliance) => {
                return {
                    id: appliance.id,
                    name: `${appliance.make}: ${appliance.model}; H:${appliance.height} x W:${appliance.width} x D:${appliance.depth}`,
                    image: appliance.imageName
                        ? `uploads/images/${getApplianceType(appliance.type)}/${
                              appliance.imageName
                          }`
                        : null,
                    type: appliance.type,
                    height: appliance.height,
                    width: appliance.width,
                    depth: appliance.depth,
                };
            });

            if ((await productRepository.getAppliances(type)).length === 0) {
                await productRepository.addAppliances(appliancesList);
            }

            appliances = appliancesList.filter(
                (appliance) => appliance.type == type
            );
        }
    }

    return appliances;
};

export const addAppliance = async (appliance) => {
    const formData = new FormData();
    Object.keys(appliance).forEach((key) => {
        formData.append(key, appliance[key]);
    });

    const response = await Axios.instance().post(
        'customer/kitchen-appliances/save',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );

    if (response.data.success) {
        await productRepository.addAppliances({
            id: response.data.data,
            name: `${appliance.make}: ${appliance.model}; H:${appliance.height} x W:${appliance.width} x D:${appliance.depth}`,
            image: appliance.imageName,
            type: appliance.type,
            height: appliance.height,
            width: appliance.width,
            depth: appliance.depth,
        });

        return response.data.data;
    }
};

export const getProductByCode = async (code) => {
    let product = await productRepository.getProduct(
        code,
        productRepository.indexes.CODE,
        true
    );

    if (product.length) {
        return product[0];
    }

    product = await productRepository.getProduct(
        code,
        productRepository.indexes.ALT_CODE,
        true
    );

    if (product.length) {
        return product[0];
    }
};
