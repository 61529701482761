import {JobRepository} from 'IndexedDB';
import Axios from './Axios';

const jobRepository = new JobRepository();

/**
 *
 * @param {boolean} mock Default value is false
 *
 * @return {Promise}
 */
export const getNotesForCustomAddress = async (mock = false) => {
    if (mock) {
        return [
            {
                note: 'All freight charges will be billed as part of the final invoice for the job',
            },
            {note: 'A minimum delivery charge will be applied to this order'},
            {
                note: 'Please contact us prior to placing this order if you require an estimate of the freight charges',
            },
            {
                note: 'Please ensure that you have a way to unload your order, larger orders may require a forklift or crane.',
            },
        ];
    } else {
        const response = await Axios.instance().get(`updates`);

        if (response.data.success) {
            return response.data.updates;
        } else throw response.data.errors;
    }
};

/**
 *
 * @param {FormData} data
 * @param {boolean|number} jobId
 *
 * @return {Promise<{success}|T>}
 */
export const saveJob = async (data, jobId = null) => {
    const header = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    let response;
    if (jobId != null) {
        response = await Axios.instance().put(
            `jobs/${jobId - 10000}`,
            data,
            header
        );
    } else {
        response = await Axios.instance().post('jobs', data, header);
    }

    let roomId = '';
    if (response.data.success) {
        // fetch new job details and save in database
        if (response.data.jobId) {
            const jobResponse = await getJob(response.data.jobId, true);
            if (jobResponse?.rooms?.length > 0) {
                roomId = jobResponse.rooms[0]?.id;
            }
        }
    }

    return {...response.data, roomId};
};

export const searchJob = async (searchString, signal) => {
    try {
        const response = await Axios.instance().post(
            `search`,
            {
                search: searchString,
            },
            {
                signal: signal,
            }
        );

        return response.data['data']['jobs'];
    } catch (err) {
        return [];
    }
};

export const getJob = async (jobId) => {
    const response = await Axios.instance().get(`jobs/${jobId}`);

    if (response.data.success) {
        if (response.data.data) {
            if (response.data.data.rooms) {
                response.data.data.rooms.map((room, index) => {
                    room.roomNumber = index + 1;
                    if (room.jobCabinets) {
                        room.jobCabinets.map((product, productIndex) => {
                            product.productNumber =
                                index + 1 + '-' + (productIndex + 1);
                        });
                    }
                    if (room.jobBenchtops) {
                        room.jobBenchtops.map((product, productIndex) => {
                            product.productNumber =
                                index + 1 + '-' + (productIndex + 1);
                        });
                    }
                });
            }

            return response.data.data;
        }
    }
};

export const updateJobInDB = async (job) => {
    await jobRepository.addJob(job);
};

export const deleteFile = async (file, jobId) => {
    const response = await Axios.instance().delete(
        `jobs/${jobId}/file/${file}`
    );

    if (response.data.success) {
        return response.data;
    }
};

export const copyJob = async (jobId) => {
    const response = await Axios.instance().get(`jobs/${jobId}/copy`);

    if (response.data.success) {
        return response.data;
    }
};

export const deleteJob = async (jobId) => {
    const response = await Axios.instance().delete(`jobs/${jobId}`);

    if (response.data.success) {
        await jobRepository.deleteJob(parseInt(jobId));
    }
};

interface ResponseData {
    success: boolean;
    message?: string;
}

export const submitJob = async (jobId: number): Promise<string[]> => {
    const response = await Axios.instance().post<ResponseData>(
        `jobs/${jobId}/submit`
    );

    jobRepository.deleteJob(jobId);

    let messages: string | string[] = 'Job Submitted';

    if (response.data.message) {
        messages = response.data.message;
    }

    if (!Array.isArray(messages)) {
        messages = [messages];
    }

    return messages;
};

export const unSubmitJob = async (jobId) => {
    await Axios.instance().post(`jobs/${jobId}/unsubmit`);
};

export const resetJobPrice = async (jobId) => {
    await Axios.instance().post(`job/${jobId}/resetPrice`);
};

export const resetJobPriceStatus = async (jobId) => {
    const response = await Axios.instance().get(
        `job/${jobId}/resetPriceStatus`
    );
    if (response.data.success) {
        return response.data.status;
    }
};
type ResponseType = {
    errors?: string;
    success: boolean;
};

export const addCouponToJob = async (
    jobId: number | string,
    couponCode: string
) => {
    const response: {data: ResponseType} = await Axios.instance().post(
        `job/${jobId}/coupon/${couponCode}`
    );
    if (response.data.success) {
        return;
    } else throw response.data.errors;
};

export const deleteCoupon = async (jobId: number | string) => {
    const response: {data: ResponseType} = await Axios.instance().post(
        `job/${jobId}/deleteCoupon/`
    );
    if (response.data.success) {
        return;
    } else throw response.data.errors;
};
