import {FingerPullRepository} from 'IndexedDB';
import Axios from './Axios';

const fingerPullRepository = new FingerPullRepository();

export const getFingerPullStyles = async (drawerGap = 0, topMargin = 0) => {
    let styles = await fingerPullRepository.getStyles();

    if (styles.length == 0) {
        const response = await Axios.instance().get(`fingerpull`);

        if (response.data.success) {
            let data = response.data.fingerPull;
            data = data.map((row) => {
                row.deleted = row.deleted ? 1 : 0;
                row.hidden = row.hidden ? 1 : 0;

                return row;
            });

            await fingerPullRepository.addStyles(data);

            styles = data.filter((row) => row.deleted === 0 && row.hidden == 0);
        } else throw response.data.errors;
    }

    styles.unshift({id: -1, name: 'None', drawerGap, topMargin});

    return styles;
};
